* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SF UI Text', "Helvetica Neue", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", sans-serif;
}

body {
  background-color: #000;
}
